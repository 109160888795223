@font-face {
  font-family: 'Calibri';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=J7adnpV-BGlaFfdAhLQo6btK&skey=36a3d5758e0e2f58&v=v15) format('truetype');
}
@font-face {
  font-family: 'Calibri';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/l/font?kit=J7aYnpV-BGlaFfdAhLQgUp5aHRgb&skey=8b00183e5f6700b6&v=v15) format('truetype');
}
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY7g&skey=a1029226f80653a8&v=v15) format('truetype');
}
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/l/font?kit=J7aanpV-BGlaFfdAjAo9_pxqGg&skey=cd2dd6afe6bf0eb2&v=v15) format('truetype');
}
