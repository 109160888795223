/* form */

.form-item-text {
  margin-bottom: 0;
  @media screen and (max-width: @screen-xs-max) {
    margin-bottom: @margin-sm;
    .ant-form-item-label {
      background: #efefef;
      padding: 0 @padding-sm 0;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 @padding-lg;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 0;
    left: 0;
    padding: 6px 0;
  }
}
