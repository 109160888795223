
@import "../../../../../assets/styles/variables.less";
@import "../../../../../assets/styles/variables-dark.less";


.SurveyQuestionResult {
  table {
    border: 1px solid @primary-color;
    tr:nth-child(odd) {
      background-color: #dad5d7;
    }
    th {
      background-color: @primary-color;
      padding: @padding-sm;
      &, h5, label {
        color: #fff;
        font-weight: 500;
      }
    }
    td {
      padding: @padding-sm;
      text-align: center;
    }
  }
}

