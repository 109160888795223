// ant design default variables
@import "~antd/lib/style/themes/default.less";
@import '~antd/dist/antd.less';
@import "./mixins";

// override ant design colors here or declare non ant design variables
@font-family: 'Calibri', 'Arial';
@primary-color: #59b343;
@font-size-base: 14px;
@menu-dark-color: #fff;

// main menu/sidebar menu
@menu-black-bg: @dark-component-background;
@menu-black-color: #fff;
@menu-black-highlight-color: @primary-color;
@menu-black-selected-item-text-color: @primary-color;
@menu-black-item-active-bg: @menu-black-bg;
@menu-black-selected-item-icon-color: @primary-color;

.questionnaire-formContent {
  background-color: @descriptions-bg;
}
