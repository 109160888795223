.ant-btn-dark {
  &.ant-btn-link {
    color: @dark-text-color;
    &:hover {
      color: @primary-color;
      background: @btn-link-hover-bg;
    }
    &:hover,
    &:focus,
    &:active {
      color: @primary-color;
      border-color: transparent;
    }
  }
}
