.app-layout {
  min-height: 100vh;
}

.container {
  &.maxWidth {
    .maxWidth();
  }
  .marginlr {
    margin-left: @margin-lg;
    margin-right: @margin-lg;
  }
  .margintop {
    margin-top: @margin-md;
  }
}

.ant-drawer .headerMenu, .ant-layout-sider .headerMenu {
  margin-top: @margin-md
}

.page-container {
  background-color: @component-background;
  border-radius: @card-radius;
}

.pointer {
  cursor: pointer;
}

// page styles
.page-header {
  margin-bottom: @margin-lg;
  padding-left: @padding-lg;
}

.page-header.maxWidth .ant-page-header-heading {
  .maxWidth();
  padding: @padding-md @padding-lg;
}

.page-header.maxWidth .ant-breadcrumb {
  .maxWidth();
  padding: 0 @padding-lg;
}

.page-container {
  margin: 0 @margin-lg @margin-md @margin-lg;
}

.page-padding-topBottom {
  padding-top: @padding-xs;
  padding-bottom: @padding-xs;
}
.page-padding-leftRight {
  padding-left: @padding-lg;
  padding-right: @padding-lg;
}

// layout misc
.center {
  text-align: center;
}

// header dropdown when visibled
.headerDropdown .ant-dropdown-menu {
  min-width: 250px;
}

// popover notifications
.popover-notifications {
  .ant-list-item-meta-content {
    width: 100%;
  }
  .ant-popover-content {
    min-width: 430px;
  }
}

.popover-notifications {
  // popover infinite scroll
  .popover-infinite-container {
    overflow: auto;
    height: 300px;
  }

  .popover-infinite-loading {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }

  .ant-list-empty-text p {
    margin-top: @margin-xs;
  }

  @media screen and (max-width: @screen-md-min) {
    width: 100%;
  }

  @media screen and (min-width: @screen-md-min + 1) {
    .ant-popover-content {
      min-width: 430px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
