.ant-layout-header.header {
  background-color: @menu-black-bg;

    .maxWidth {
      .maxWidth();
    }

    &,
    .ant-row {
      height: 80px;
    }
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 32px;
    @media screen and (max-width: @screen-lg-min - 1) {
      padding-right: 16px;
    }


    .headerMenu.ant-menu {
      height: 80px;
      padding: 0 @padding-lg;
      line-height: 80px;
      @media screen and (max-width: @screen-lg-min - 1) {
        display: none;
      }
    }

  .header-col {
    display: flex;
    justify-content: flex-end;
    padding-right: @padding-lg;

    a {
      & span {
        color: @black;
        &:hover {
          color: @primary-color;
        }
      }
    }
  }


.header-logo {
  padding-left: 110px;
  padding-right: 86px;
  img {
    width: auto;
    height: 80px
  }
  @media screen and (max-width: @screen-lg-min - 1) {
    display: none;
  }
}

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 @padding-lg;
    cursor: pointer;
    transition: color 0.3s;
    @media screen and (min-width: @screen-lg-min) {
      display: none;
    }
  }

  .trigger:hover {
    color: #1890ff;
  }

  &.header .maxWidth {
    .maxWidth();
  }

  .headerMenu {
    color: @menu-black-color;
    background: @menu-black-bg;
    max-height: 80px;
    font-weight: 700;
  }

  .accountDropdrownShortText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95px;
    vertical-align: middle;
  }
}

.menu-logo {
  margin: @margin-md @margin-md 0 @margin-md;
  text-align: center;
  img {
    width: auto;
    max-height: 80px;
  }
}

.result-logo {
  margin: @margin-md @margin-md 0 @margin-md;
  text-align: center;
  img {
    width: auto;
  }
}

.headerMenu-name {
  text-align: center;
  padding: @padding-md @padding-md 0 @padding-md;
}
