
.icTemplateSwitch.ant-switch {
    background-color: #1890ff;
}

.rst__category_background .rst__rowContents{
    background-color: lightcyan;
}

.rst__question_background .rst__rowContents{
    background-color: lightgoldenrodyellow;
}

.rst__rowContents {
  min-width: auto !important;
  font-size: 11px;
}

.rstcustom__rowLabel {
  padding-right: 0;
}

.rstcustom__rowContents {
  button {
    padding: 0 @padding-sm 0 @padding-sm;
    height: auto;
  }
  .icNodeIcon {
    display: none;
  }
  &:hover .icNodeIcon {
    display: inline-block;
  }
}
