.ant-menu.ant-menu-dark{
  &,
  &.ant-menu-sub,
  .ant-menu-sub {
    color: @menu-black-color;
    background: @menu-black-bg;
    /*
    .ant-submenu-title .ant-submenu-arrow {
      &::after,
      &::before {
        background: @menu-dark-arrow-color;
      }
    }
    */
    &.ant-menu-submenu-popup {
      &,
      .ant-menu-sub {
        background: @menu-dark-bg;
      }
    }
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-submenu-open,
  .ant-menu-submenu-title:hover {
    color: @dark-text-color;
    background-color: transparent;
    > a,
    > span > a {
      color: @dark-text-color;
    }
    > .ant-menu-sub-submenu-title {
      > .ant-sub-submenu-arrow {
        &::after,
        &::before {
          background: @menu-black-highlight-color;
        }
      }
    }
  }
  
  .ant-menu-item:hover {
    background-color: @menu-dark-item-hover-bg;
  }

  &.ant-menu-dark.ant-menu-dark:not(&-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }

  .ant-menu-submenu-selected {
    color: @primary-color;
  }

  .ant-menu-item-selected {
    color: @primary-color;
    border-right: 0;
    &::after {
      border-right: 0;
    }
    > a,
    > span > a,
    > a:hover,
    > span > a:hover {
      color: @primary-color;
    }

    .ant-menu-item-icon,
    .@{iconfont-css-prefix} {
      color: @menu-black-selected-item-icon-color;

      + span {
        color: @menu-black-selected-item-text-color;
      }
    }
  }

  &.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: @menu-black-item-active-bg;
  }

  &.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: transparent;
  }

}
