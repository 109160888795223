.statistic-row {
    .ant-col {
        text-align: center;
    }
    .ant-col:not(:first-child) {
        @media screen and (min-width: @screen-xs-max) {
            border-left: 1px solid @border-color-base;
        }
    }
    .ant-col:not(:last-child) {
        @media screen and (max-width: @screen-xs-max - 1) {
            .ant-statistic {
                margin-bottom: @margin-md;
            }
        }
    }
}