.ant-layout-sider.ant-layout-sider-dark {
  .ant-menu:not(.ant-menu-sub).ant-menu-inline {
    border-left: 10px solid #313131 !important;
    border-right: 10px solid #313131 !important;
  }
  &,
  .ant-menu,
  .ant-menu.ant-menu-dark{
    background: #313131;
  }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0;
  }
  .title {
    color: #fff;
    font-size: 15px;
    margin-top: 12px;
    margin-left: 24px;
    font-weight: 400;
    letter-spacing: 1.1px;
    text-transform: uppercase;
  }
  .ant-menu-submenu-title {
    padding-left: 24px;
  }
  .sideMainmenu > .ant-menu-item {
    padding-left: 24px;
  }
  .ant-menu.questionnaireMenu,
  .ant-menu .ant-menu-submenu-open .ant-menu-sub {
      background-color: #DEDBDB;

  }
  .menuItemLight:not(.ant-menu-item-selected),
  .submenuLight > .ant-menu-submenu-title {
    &, .ant-menu-title-content,
    .ant-menu-title-content > a {
      color: #fff;
    }
    .ant-menu-submenu-arrow {
      color: #fff;
    }
  }

  .ant-menu-item-icon{
    color: #000;
  }

  .adminMenu {
    > li.ant-menu-submenu-open:not(.ant-menu-item-selected) > .ant-menu-submenu-title > .ant-menu-item-icon,
    > li:not(.ant-menu-submenu-open):not(.ant-menu-item-selected) .ant-menu-item-icon{
      color: #fff;
    }
  }
}

.sidebarDrawer {
  .ant-drawer-content {
      background: #313131;
  }
  .ant-drawer-body {
    overflow-x: hidden;
  }
}
