.page-container-verification {
    margin-top: @margin-lg;
    .card-form-button {
        width: 100%;
    }
    .card-bottomLink {
      text-align: center;
      margin-top: @margin-md;
    }
}
