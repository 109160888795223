.ant-dropdown-menu-dark,
.ant-dropdown-dark {
  .ant-dropdown-menu-dark .ant-dropdown-menu,
  .ant-dropdown-menu {
    background-color: @dark-dropdown-menu-bg;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: @dark-text-color;
     &:hover {
       background-color: @dark-item-hover-bg;
     }
  }
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selecte {
     color: @primary-color;
     background-color: @dark-item-active-bg;
   }

   .ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
     background-color: @dark-border-color-split;
   }
   
   .ant-btn-link {
     color: @dark-text-color;
   }

   .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
     color: white;
   }
}
