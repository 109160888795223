.questionnaireProgress {
  margin-bottom: 12px;
  .ant-progress-bg,
  .ant-progress-inner {
    height: 16px !important;
    border-radius: 0 !important;
  }
}

.radioRate {
  background: #fff;
  .ant-radio-button-wrapper {
    text-align: center;
    padding-top: 3px;
    height: auto;
    &.ant-radio-button-wrapper-checked {
      outline: 2px solid @primary-color;
    }
    &,&:before,&:hover:before {
      background-color: transparent;
      border: none !important;
      border-left: none !important;
    }
  }
}

.radioImage {
  .ant-radio-inner {
    position: absolute !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }

  .ant-radio-wrapper {
    text-align: center;
    padding-top: 3px;
    height: auto;
    cursor: pointer;
  }

  .ant-radio-wrapper-checked {
    padding-top: 8px;
    outline: 2px solid @primary-color;
  }

}

.checkboxImage {
  .ant-checkbox-inner {
    position: absolute !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }

  .ant-checkbox-wrapper {
    text-align: center;
    padding-top: 3px;
    height: auto;
    cursor: pointer;
  }

  .ant-checkbox-wrapper-checked {
    outline: 2px solid @primary-color;
  }

  @media screen and (min-width: @screen-md-min) {
    .ant-avatar-image {
      margin-right: 0!important;
    }

  }

}
