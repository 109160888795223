/* Modal dialog */
.modalDialogSuccess, .modalDialogError {
  .ant-modal-confirm-title .anticon {
    font-size: 22px;
  }
  .ant-modal-confirm-content {
    margin-left: 38px
  }
}

.modalDialogSuccess .ant-modal-confirm-title .anticon {
  color: @success-color;
}

.modalDialogError .ant-modal-confirm-title .anticon {
  color: @error-color;
}
