.ant-layout-footer.footer {
  color: @dark-text-color;
  background-color: @dark-component-background;
  .container-footer {
  }
  overflow-y: hidden;
  .ant-typography, h3 {
    color: @dark-text-color;
  }
  h3 {
    font-size: 1em;
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin-bottom: 0.5em;
    list-style: none;
  }
  a {
    color: @dark-text-color;
  }

  .ant-col {
    margin-bottom: @margin-lg;
  }

  .footer-copyright {
    text-align: center;
    //margin-top: 50px;

    @media screen and (max-width: @screen-xs-max) {
      font-size: 0.9em;
      span {
        display: block;
      }
    }
  }
}
